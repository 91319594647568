import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useSelector } from "react-redux";
import { GraphState, setSelected } from "../redux/graph.slice";
import { AppState } from "../redux/store";
import { EsComponentProps, ExtendedGeoGraph } from "../types";
import { translate } from "../common";
import { useDispatch } from "react-redux";
import { ControlDataCache } from "../services/control-data-cache.service";


export function PlanetsTable(props: EsComponentProps) {

    const dispatch = useDispatch()
    const graphState = useSelector<AppState>(state => state.graph) as GraphState;

    const nameBodyTemplate = (data: ExtendedGeoGraph) => {

        return <span style={{borderLeft: `8px solid#${data.body.color}`, paddingLeft: 2}}>{data.body.name}</span>
    }

    const xBodyTemplate = (data: ExtendedGeoGraph) => {
        return <span>{data.x.toFixed(2)}</span>
    }

    const yBodyTemplate = (data: ExtendedGeoGraph) => {
        return <span>{data.y.toFixed(2)}</span>
    }

    const handleSelectionChanged = (e: any) => {
        const items = e.value as ExtendedGeoGraph[];
        ControlDataCache.selected = items.map(x => x.body.id);
        dispatch(setSelected(items))
        
    }

    return (
        <DataTable value={graphState.matrix} size="small" className={`planet-table ${props.className ?? ''}`} selectionMode={"checkbox"} 
        selection={graphState.selected} onSelectionChange={handleSelectionChanged}>
            <Column selectionMode="multiple" headerStyle={{ width: '20px' }}></Column>
            <Column field="name" header={translate("NAME")} body={nameBodyTemplate}></Column>
            <Column field="x" header={translate("X")} body={xBodyTemplate}></Column>
            <Column field="y" header={translate("Y")} body={yBodyTemplate}></Column>

        </DataTable>
    )

}