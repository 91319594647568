import { interval } from "date-fns";
import { ControlPanelData } from "../types";
import { DataStore, DataStoreKeys } from "./data-store.service";
import { store } from "../redux/store";
import settings from '../settings.json';




export class ControlDataCache {

    public static set controlPanel(data: ControlPanelData) {
        DataStore.setData(DataStoreKeys.CONTROL_PANEL, data);
    }

    public static get controlPanel(): ControlPanelData {
        let state = store.getState().graph;
        let data = DataStore.getData(DataStoreKeys.CONTROL_PANEL) as ControlPanelData;
        if (!data) {
            data = {
                active: state.active,
                interval: state.interval,
                step: state.step,
                zoom: state.zoom,
                defaultSelected: settings.defaultPlanets
            }
        }
        return data;
       
    }

    public static get interval(): number {
        let data = ControlDataCache.controlPanel;
        return data.interval;
    }

    public static set interval(v: number) {
        let data = ControlDataCache.controlPanel;
        data.interval = v;
        ControlDataCache.controlPanel = data;
    }

    public static get step(): number {
        let data = ControlDataCache.controlPanel;
        return data.step;
    }

    public static set zoom(v: number) {
        let data = ControlDataCache.controlPanel;
        data.zoom = v;
        ControlDataCache.controlPanel = data;
    }

    public static get zoom(): number {
        let data = ControlDataCache.controlPanel;
        return data.zoom;
    }

    public static set step(v: number) {
        let data = ControlDataCache.controlPanel;
        data.step = v;
        ControlDataCache.controlPanel = data;
    }

    public static get active(): boolean {
        let data = ControlDataCache.controlPanel;
        return data.active;
    }

    public static set active(v: boolean) {
        let data = ControlDataCache.controlPanel;
        data.active = v;
        ControlDataCache.controlPanel = data;
    }

    public static get selected(): string[] {
        let data = ControlDataCache.controlPanel;
        return data.defaultSelected;
    }

    public static set selected(v: string[]) {
        let data = ControlDataCache.controlPanel;
        data.defaultSelected = v;
        ControlDataCache.controlPanel = data;
    }

}