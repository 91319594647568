import { configureStore, Tuple } from "@reduxjs/toolkit";
import { Middleware } from "redux";
import { thunk } from "redux-thunk";
import { datesToString } from "../utils";
import graphReducer, { GraphState, initGraphState } from "./graph.slice";


export interface AppState {
    graph: GraphState;
    
}



export const initializeAppState: AppState = {
    graph: initGraphState
}


export const dateMiddleware: Middleware = api => next => (action: any) => {

    if (action.payload) {
      action.payload = datesToString(action.payload)
    }
    if (action.meta) {
      action.meta = datesToString(action.payload)
    }
    return next(action);
  };


  export const store = configureStore({
    reducer: {
      graph: graphReducer,
     
    },
    middleware: () => new Tuple(thunk, dateMiddleware)
  })
  
  