import { CombinedCalcs, GeocentricCalcs, PhaseInfo, PhasesCalcs } from "@liminil/estreon-sdk";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { translate } from "../common";
import { useSelector } from "react-redux";
import { GraphState } from "../redux/graph.slice";
import { AppState } from "../redux/store";
import { fixDate } from "./graph";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { formatDate } from "../utils";
import { EsComponentProps } from "../types";



export default function PhasesTable(props: EsComponentProps) {

    const decimalPlaces = 2;
    const [_data, setData] = useState<PhaseInfo[]>();
    const graphState = useSelector<AppState>(state => state.graph) as GraphState;

    useEffect(() => {
        let service = new PhasesCalcs()
        let date = fixDate(graphState.activeDate)
        let data = service.execute(date, new Date(2023, 0, 25))
        setData(data)
    }, [graphState.activeDate])

    const daysToGoBody = (data: PhaseInfo) => {
        return data.daysToGo.toFixed(decimalPlaces)
    }

    const deltaBody = (data: PhaseInfo) => {
        return data.delta.toFixed(decimalPlaces)
    }

    const muBody = (data: PhaseInfo) => {
        return data.mu.toFixed(decimalPlaces)
    }

    const phaseBody = (data: PhaseInfo) => {
        return `${(data.phase * 100).toFixed(decimalPlaces)}%`
    }

    const standardDateBody = (data: PhaseInfo) => {
        return formatDate(data.standardDate, true)
    }

    const adjustedDateBody = (data: PhaseInfo) => {
        return formatDate(data.adjustedDate, true)
    }

    const nextDateBody = (data: PhaseInfo) => {
        return formatDate(data.nextDate, true)
    }

    const postWeatherDateBody = (data: PhaseInfo) => {
        return formatDate(data.postWeatherDate, true)
    }


    const deltaMuBody = (data: PhaseInfo) => {
        return data.distance.toFixed(decimalPlaces)
    }

    const outboundBody = (data: PhaseInfo) => {
        return data.outerBound?.toFixed(decimalPlaces)
    }

    const columnGroup = (
        <ColumnGroup>
            <Row>
                <Column header={''} colSpan={4}></Column>
                <Column header={translate("F")} rowSpan={1}></Column>
                <Column header={translate("d^-(1/2)")} rowSpan={1}></Column>
                <Column header={''} colSpan={5}></Column>
            </Row>
            <Row>
                <Column header={translate("NAME")} rowSpan={2}></Column>
                <Column header={translate("DAYS_TO_GO")} rowSpan={2}></Column>
                <Column header={translate("PHASE")} rowSpan={2}></Column>
                <Column header={translate("MU_SYMBOL")} rowSpan={2}></Column>
                <Column header={translate("STANDARD")} rowSpan={1}></Column>
                <Column header={translate("ADJUSTED")} rowSpan={1}></Column>
                <Column header={translate("DELTA_SYMBOL")} rowSpan={2}></Column>
                <Column header={translate("MU_DELTA_SYMBOL")} rowSpan={2}></Column>
                <Column header={translate("OUTERBOUND")} rowSpan={2}></Column>
                <Column header={translate("NEXT")} rowSpan={2}></Column>
                <Column header={translate("POST_WEATHER")} rowSpan={2}></Column>
            </Row>

        </ColumnGroup>
    )


    return (
        <DataTable value={_data} size="small" headerColumnGroup={columnGroup} className={`phases-table ${props.className ?? ''}`}>
            <Column field="name" header={translate("NAME")}></Column>
            <Column field="daysToGo" header={translate("DAYS_TO_GO")} body={daysToGoBody}></Column>
            <Column field="phase" header={translate("PHASE")} body={phaseBody}></Column>
            <Column field="mu" header={translate("MU")} body={muBody}></Column>
            <Column field="standard" header={translate("STANDARD")} body={standardDateBody}></Column>
            <Column field="adjusted" header={translate("ADJUSTED")} body={adjustedDateBody}></Column>
            <Column field="delta" header={translate("DELTA_SYMBOL")} body={deltaBody}></Column>
            <Column field="distance" header={translate("DELTA_SYMBOL")} body={deltaMuBody}></Column>
            <Column field="outBound" header={translate("OUTERBOUND")} body={outboundBody}></Column>
            <Column field="nextDate" header={translate("NEXT")} body={nextDateBody}></Column>
            <Column field="weatherDate" header={translate("POST_WEATHER")} body={postWeatherDateBody}></Column>
        </DataTable>
    )
}