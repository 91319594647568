
import { format, formatDuration, parseISO } from 'date-fns';
import settings from '../settings.json'

export const regexIso8601 = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([.\d]*)Z/;

export const reviveDateTime = (key: any, value: any) => {
  var match;
  if (typeof value === "string" && (match = value.match(regexIso8601))) {
    var milliseconds = Date.parse(match[0]);
    if (!isNaN(milliseconds)) {
      return new Date(milliseconds);
    }
  }
  return value;
}

export function isDate(value: string): boolean {
  let yearFirst = /^([0-9]{4})([\/-])([0-9]{1,2})([\/-])([0-9]{1,2})/
  let yearLast = /([0-9]{1,2})([\/-])([0-9]{1,2})([\/-])([0-9]{4})\Z/

  return yearFirst.test(value) || yearLast.test(value) || regexIso8601.test(value);

}

export function getDateFormat(value: string) {
  let yearFirst = /^([0-9]{4})([\/-])([0-9]{1,2})([\/-])([0-9]{1,2})/
  let yearLast = /([0-9]{1,2})([\/-])([0-9]{1,2})([\/-])([0-9]{4})\Z/
  let iso8601 = regexIso8601.test(value);

  return {
    yearFirst: yearFirst.test(value),
    yearLast: yearLast.test(value),
    iso8601: iso8601
  }

}

export function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && regexIso8601.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      var milliseconds = Date.parse(value);
      if (!isNaN(milliseconds)) {
        body[key] = new Date(milliseconds);
      }
    }
    else if (typeof value === "object") handleDates(value);
  }
}

export function formatTime(date?: Date | string): string {
  if (date) {
    let value: Date;
    if (typeof date === 'string') {
      value = parseISO(date)
    } else {
      value = date;
    }

    return format(value, settings.timeFormat ?? 'HH:mm:ss');

  }
  return '';
}


export function formatDate(date?: Date | string, dateOnly?: boolean): string {
  if (date) {
    let value: Date;
    if (typeof date === 'string') {
      value = parseISO(date)
    } else {
      value = date;
    }
    if (!dateOnly)
      return format(value, (settings.dateFormat + " " + settings.timeFormat) ?? 'yyyy-MM-dd HH:mm:ss');
    else
      return format(value, settings.dateFormat ?? 'yyyy-MM-dd');
  }
  return '';
}


export function humanizeDuration(seconds: number): string {
  let duration = {
    seconds: seconds
  };
  return formatDuration(duration);
}



export function datesToString(obj: any): any {

  if (Array.isArray(obj)) {
    return obj.map(x => datesToString(x));
  }

  if (obj) {
    for (const [key, value] of Object.entries(obj)) {
      if (value instanceof Date) {
        obj[key] = value.toJSON();
      } else if (typeof value === 'object') {
        datesToString(value);
      }
    }

  }
  return obj;
}

