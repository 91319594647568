
import {  createIntl, createIntlCache } from "react-intl";
import messageJson from './translations.json';
import settings from '../../settings.json'

// const _locale =  navigator.language;
// const languageCode: string = _locale.split('-')[0];

const languageCode = settings.language;

interface languageFile {
    [key: string]: {
        [key: string]: string;
    }
}

const cache = createIntlCache();
export const messages = messageJson as languageFile;


let int = createIntl(
  {
    locale: languageCode,
    messages: messages[languageCode]
  },
  cache
);

export function changeLanguage(lang: string) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang]
    },
    cache
  );
  int = newIntl;
}

export const translate = (id: string, values?: {}) => {
  try {
    if (int)
        return int.formatMessage({ id: id, defaultMessage: id }, values);
    return id;
} catch {
    return id;
}
};

