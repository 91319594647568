import { GeoGraph, oneDay } from "@liminil/estreon-sdk";
import { useCallback, useEffect, useState } from "react";
import { ResponsiveContainer, ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Scatter, ReferenceLine, Dot } from "recharts";
import { useSelector } from "react-redux";
import { GraphState, loadMatrix, setActive, setZoom } from "../redux/graph.slice";
import { AppState } from "../redux/store";
import { ExtendedGeoGraph } from "../types";
import ReactSlider from 'react-slider';
import { parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { formatDate } from "../utils";
import { ControlDataCache } from "../services/control-data-cache.service";
import settings from '../settings.json';

export interface GraphProps {
    width: string | number;
    height: string | number;
    name: string;
    className?: string;
}

const oneHour = 60 * 60 * 1000;

export const fixDate = (date: Date | string) => {


    if (typeof date === 'string') {
        return parseISO(date)
    } else {
        return date;
    }
}

export function Graph(props: GraphProps) {

    const dispatch = useDispatch<any>();
    const graphState = useSelector<AppState>(state => state.graph) as GraphState;
    const [_trackMin, setTrackMin] = useState<number>(0)
    const [_trackMax, setTrackMax] = useState<number>(0)
    const [_trackValue, setTrackValue] = useState<number>(0)
    const [_matrix, setMatrix] = useState<ExtendedGeoGraph[]>([])

    useEffect(() => {
        if (graphState.startDate) {
            let date = fixDate(graphState.startDate)
            let time = date.getTime() / oneHour
            setTrackMin(time)
        }
    }, [graphState.startDate])

    useEffect(() => {
        if (graphState.endDate) {
            let date = fixDate(graphState.endDate)
            let time = date.getTime() / oneHour
            setTrackMax(time)
        }
    }, [graphState.endDate])

    useEffect(() => {
        if (graphState.activeDate) {
            let date = fixDate(graphState.activeDate)
            let time = date.getTime() / oneHour
            setTrackValue(time)
        }
    }, [graphState.activeDate])

    useEffect(() => {
        setMatrix(graphState.zoomMatrix)
    }, [graphState.zoomMatrix])

    const handleDateChange = (value: number, index: number) => {
        let date = new Date(value * oneHour);
        dispatch(setActive(false))
        dispatch(loadMatrix(date))
       
        setTrackValue(value)
    }

    const handleZoomChange = (value: number, index: number) => {
        let zoom = (value) / 100;
        ControlDataCache.zoom = zoom;
        dispatch(setZoom(zoom))
    }


    const CustomTooltip = (e: any) => {
        let { active, payload, label } = e;
        if (active && payload && payload.length) {
            let body = payload[0].payload?.body
            return (
                <div className="custom-tooltip">
                    <p className="label">{body?.name}</p>
                    <p className="label">{`X: ${payload[0].value.toFixed(2)}`}</p>
                    <p className="label">{`Y: ${payload[1].value.toFixed(2)}`}</p>

                </div>
            );
        }

        return null;
    };

    const renderDateThumb = (props: any, state: any) => {
        let date = new Date(state.valueNow * oneHour)
        let dateStr = formatDate(date)


        return (<div {...props}>{dateStr }</div>)
    }

    const renderZoomThumb = (props: any, state: any) => {
        const str = `${state.value}%`;

        return (<div {...props}>{str}</div>)
    }


    const RenderDot = useCallback((e: any) => {
        let { cx, cy, fill, stroke, r } = e
        let size = settings.dotSize;
        if (graphState.relativeSizes) {
            if (e.payload.body.id === 'sun')
                size = 10;
            else {
                if (graphState.relativeAlgorithm === 'log') {
                    size = Math.abs(Math.log10(r) * 20) + 5
                } else {
                    size = r * 20;
                }

            }
        }
        return (

            <Dot cx={cx} cy={cy} fill={fill} r={size} stroke={stroke} strokeWidth={1} opacity={1} ></Dot>
        )
    }, [graphState.relativeSizes])




    return (
        <div className={`${props.className ?? ''}`} id='graph' style={{ height: props.height, width: props.width }}>
            <div className="grid graph-top-container">
                <div className="col-auto graph-zoom-container" >
                    <ReactSlider orientation="vertical" className="vertical-slider zoom-slider"
                        thumbClassName="thumb" min={10} max={100} onChange={handleZoomChange} value={graphState.zoom * 100}
                        trackClassName="track" renderThumb={renderZoomThumb} />
                </div>
                <div className="col-11 graph-graph-container" >
                    <div style={{ height: '100%', width: '100%' }} >
                        <ResponsiveContainer height='100%' width='100%' >
                            <ScatterChart
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    left: 20,
                                }}
                            >
                                <CartesianGrid />
                                <XAxis type="number" dataKey="x" name="x" tickCount={20} domain={[graphState.axisValue * -1, graphState.axisValue]} />
                                <YAxis type="number" dataKey="y" name="y" tickCount={20} domain={[graphState.axisValue * -1, graphState.axisValue]} />
                                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
                                <Scatter name={props.name} data={_matrix} fill="#8884d8" shape={<RenderDot />} />
                                <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />
                                <ReferenceLine x={0} stroke="green" strokeDasharray="3 3" />
                            </ScatterChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            </div>
            <div className="date-slider-container" >
                <ReactSlider className="horizontal-slider date-slider"
                    thumbClassName="thumb"
                    trackClassName="track" min={_trackMin} max={_trackMax} step={graphState.step} value={_trackValue} onChange={handleDateChange}
                    renderThumb={renderDateThumb} />
            </div>
        </div>
    )

}