import {  useEffect, useState } from "react";
import { translate } from "../common";
import { useSelector } from "react-redux";
import { GraphState } from "../redux/graph.slice";
import { AppState } from "../redux/store";
import { calcD, correlation, obliquity } from "@liminil/estreon-sdk";
import { fixDate } from "./graph";
import { EsComponentProps } from "../types";





export default function ExtraInfoBox(props: EsComponentProps) {

    const [_obliquity, setObliquity] = useState<number>(0)
    const [_correlation, setCorrelation] = useState<number>(0)
    const graphState = useSelector<AppState>(state => state.graph) as GraphState;


    useEffect(() => {
        let date = fixDate(graphState.activeDate)
        const d = calcD(date)
        setObliquity(obliquity(d));

        let matrix = graphState.matrix;
        if (matrix.length > 0) {
        let corArray = matrix.filter(x => x.body.correlate)
        let corX = corArray.map(x => x.x)
        let corY = corArray.map(x => x.y)

        let corrValue = correlation(corX, corY)
        setCorrelation(corrValue);
        }
    }, [graphState.activeDate, graphState.matrix])

    return (
        <div className={`extra-info-box grid ${props.className ?? ''}`}>
            <div className="col-12">
                <label>{translate("OBLIQUITY")}</label>
                <span className="extra-info-data">{_obliquity?.toFixed(2)}</span>
            </div>

            <div className="col-12">
                <label>{translate("COV")}</label>
                <span className="extra-info-data">{`${(_correlation * 100).toFixed(2)}%`}</span>
            </div>
        </div>
    )
}