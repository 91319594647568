

export const csBodyColors: any[] = [
    { id: "mercury", color: "909090", scale: 0.40 },
    { id: "venus", color: "D9C295", scale: 0.95 },
    { id: "earth", color: "2E70B8", scale: 1 },
    { id: "mars", color: "C1440E", scale: 0.53 },
    { id: "jupiter", color: "BF40BF", scale: 11 },
    { id: "saturn", color: "D4BF8D", scale: 9 },
    { id: "uranus", color: "8FDDE7", scale: 4 },
    { id: "neptune", color: "2B65EC", scale: 4 },
    { id: "pluto", color: "8B7D6B", scale: 0.2 },
    { id: "moon", color: "696969", scale: 0.25 },
    { id: "sun", color: "ffff00", scale: 25 }
  ]