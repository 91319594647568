import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

import '../node_modules/primeflex/primeflex.css';
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PrimeReactProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PrimeReactProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
