import { addHours, isAfter, subHours } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { ControlBox } from './components/control-box';
import { Graph } from './components/graph';
import { GraphState, loadMatrix, setActive, setZoom, setInterval, setStep, setDefaultSelected } from './redux/graph.slice';
import { AppState } from './redux/store';
import { ControlDataCache } from './services/control-data-cache.service';
import { PlanetsTable } from './components/planets-table';
import PhasesTable from './components/phases-table';
import ExtraInfoBox from './components/extra-info-box';
import { TDataTable } from './components/tdata-table';





function App() {

  const dispatch = useDispatch<any>();
  const graphState = useSelector<AppState>(state => state.graph) as GraphState;
  // const [_activeDate, setActiveDate] = useState<Date | null>(new Date())

  //useEffect(() => { setActiveDate(graphState.activeDate) }, [graphState.activeDate])

  useEffect(() => {
    dispatch(loadMatrix(graphState.activeDate ?? new Date()))

    let data = ControlDataCache.controlPanel;
    if (data) {
      dispatch(setInterval(data.interval))
      dispatch(setStep(data.step))
      dispatch(setActive(data.active))
      dispatch(setZoom(data.zoom))
      dispatch(setDefaultSelected(data.defaultSelected))
    }
  }, [])


  const incDate = useCallback((direction: 'next' | 'prev') => {
    if (!graphState.busy) {
      let date = graphState.activeDate;
      if (!date) {
        date = graphState.startDate ?? new Date();
      }
      if (direction === 'next')
        date = addHours(date, graphState.step)
      else
        date = subHours(date, graphState.step)

      if (graphState.endDate && isAfter(date, graphState.endDate))
        date = graphState.startDate ?? new Date();

      dispatch(loadMatrix(date))
    }
  }, [graphState.activeDate, graphState.step, graphState.busy])

  useEffect(() => {
    if (graphState.active && !graphState.busy)
      setTimeout(() => incDate("next"), graphState.interval)

  }, [graphState.activeDate, graphState.active, graphState.step, graphState.interval])




  return (
    <div className="App">
      <div className="home-container">
        <div className='side-bar' >
          <div className="side-bar-menu">
            <ControlBox />
          </div>

          <div className="side-bar-extra-info">
            <ExtraInfoBox />
          </div>
          <div className="side-bar-tcalc">
            <TDataTable />
          </div>
          <div className="side-bar-data">
            <PlanetsTable />
          </div>

        </div>
        <div className='graph-container'>
          <div className='home-graph'>
            <Graph name="chart2" height={'100%'} width={'calc(100% - 10px)'} className="estreon-graph" />
          </div>
          <div className='home-phases'>
            <PhasesTable />
          </div>

        </div>


      </div>


    </div>
  );
}

export default App;
