import { reviveDateTime } from "../utils";



export class DataStore {

    public static setData(key: string, data: any) {
        let json = JSON.stringify(data);
        localStorage.setItem(key, json);
    }

    public static getData(key: string): any {
        let json = localStorage.getItem(key);
        if (json && json !== "{}")
            return JSON.parse(json, reviveDateTime);
        return null;
    }

    public static removeData(key: string) {
        localStorage.removeItem(key);
    }

    public static keys() {
        return Object.keys(localStorage);
    }

    public static clearAll() {
        localStorage.clear();
    }
}
    

export class DataStoreKeys {
    public static CONTROL_PANEL = 'control-panel';
}