import { GeocentricCalcs } from "@liminil/estreon-sdk";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addYears, parseISO } from "date-fns";
import { ControlPanelData, csBodyColors, ExtendedGeoGraph } from "../types";
import { roundTo } from "../utils";
import settings from '../settings.json'
import { AppState } from "./store";

export type sizeAlgorithm = 'log' | 'linear';
const defaultAxis = 100;

export interface GraphState extends ControlPanelData {
    matrix: ExtendedGeoGraph[];
    zoomMatrix: ExtendedGeoGraph[];
    startDate: Date;
    endDate: Date;
    activeDate: Date;
    axisValue: number;
    relativeSizes: boolean
    relativeAlgorithm: sizeAlgorithm;
    busy: boolean;
    selected: ExtendedGeoGraph[];

}


export const initGraphState: GraphState = {
    matrix: [],
    zoomMatrix: [],
    zoom: 1,
    startDate: new Date(),
    endDate: addYears(new Date(), 1),
    activeDate: new Date(),
    axisValue: 100,
    relativeSizes: false,
    relativeAlgorithm: "log",
    busy: false,
    step: 6,
    interval: 100,
    active: false,
    selected: [],
    defaultSelected: []
}


export const loadMatrix = createAsyncThunk(
    'graph/loadMatrix',
    (date: Date, { getState }) => {

        let state = (getState() as AppState).graph;
        let selectedPlanets = state.selected.map(x => x.body.id)
        let defaultPlanets = new Array<string>();
        if (selectedPlanets.length > 0)
            defaultPlanets = selectedPlanets;
        else
            defaultPlanets = state.defaultSelected?.length > 0 ? state.defaultSelected : [...settings.defaultPlanets]

        let geoCalc = new GeocentricCalcs();
        if (typeof date === 'string') {
            date = parseISO(date);
        }

        let matrix = geoCalc.graphGrid(date ?? new Date()) as ExtendedGeoGraph[];
        let selected = new Array<ExtendedGeoGraph>();

        matrix.forEach(m => {
            let clr = csBodyColors.find(x => x.id === m.body.id)
            if (clr) {
                let body = { ...m.body }
                body.color = clr.color
                m.body = body;
            }
            m.fill = `#${m.body.color}`;
            m.stroke = `black`;
            m.r = m.body.scale;

            if (defaultPlanets.includes(m.body.id)) {
                selected.push(m);
            }
        })

        matrix.sort((a, b) => a.body.index - b.body.index)

        return {
            matrix: matrix,
            date: date,
            selected: selected
        }

    }
)

const filterMatrix = (matrix: ExtendedGeoGraph[], axis: number) => {
    let min = axis * -1;
    let max = axis;
    return matrix.filter(z => (z.x <= max && z.x >= min) && (z.y <= max && z.y >= min))
}




const GraphSlice = createSlice({
    name: 'graph',
    initialState: initGraphState,
    reducers: {
        setZoom(state, action) {
            const zoom = action.payload;
            state.zoom = zoom;



            let axis = roundTo(defaultAxis * zoom, 5)
            let matrix = filterMatrix(state.selected, axis)
            state.zoomMatrix = matrix;
            state.axisValue = axis;
        },
        setInterval(state, action) {
            state.interval = action.payload;

        },
        setStep(state, action) {
            state.step = action.payload;

        },
        setSelected(state, action) {
            state.selected = action.payload;

            let axis = roundTo(defaultAxis * state.zoom, 5)
            let matrix = filterMatrix(state.selected, axis)
            state.zoomMatrix = matrix;
            state.axisValue = axis;

        },
        setEndDate(state, action) {
            state.endDate = action.payload
        },
        setStartDate(state, action) {
            state.startDate = action.payload
        },
        setActive(state, action) {
            state.active = action.payload

        },
        setRelativeSizes(state, action) {
            state.relativeSizes = action.payload
        },
        setDefaultSelected(state, action) {
            state.defaultSelected = action.payload
        }


    },
    extraReducers: (builder) => {

        builder.addCase(loadMatrix.pending, (state, action) => {
            state.busy = true;
        })
        builder.addCase(loadMatrix.fulfilled, (state, action) => {
            const { matrix, date, selected } = action.payload;

            state.matrix = matrix;
            state.zoomMatrix = filterMatrix(selected, state.axisValue)
            state.activeDate = date;
            state.busy = false;
            state.selected = selected;
        })
        builder.addCase(loadMatrix.rejected, (state, action) => {
            state.busy = false;
            throw action.error;
        })


    }


})


export const { setZoom, setInterval, setStep, setEndDate, setStartDate, setActive, setSelected, setDefaultSelected } = GraphSlice.actions
export default GraphSlice.reducer